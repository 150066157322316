/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../header/css/index.css"
// import styled from 'styled-components'
import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
	Drawer,
	Button,
	Menu,
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../mamager/ScreenManager"
import { scroller } from "react-scroll"

// const CustomMenu = styled(Menu)`
// 	background-color: rgba(0, 0, 0, 0);
// 	color: white;
// `

export default function Header(props) {
	const navigate = useNavigate()
	const location = useLocation()

	const { width } = useDimensions()

	const [state, setState] = useState({
		current: 'mail',
		visible: false
	})

	const showDrawer = () => {
		setState({
			visible: true
		})
	}

	const onClose = () => {
		setState({
			visible: false
		})
	}

	const genRightMenu = () => {
		return (
			<Menu mode="inline">
				<Menu.Item
					key="1"
					onClick={() => {
						openPath("home")
					}}
				>
					หน้าเเรก
				</Menu.Item>

				<Menu.Item
					key="2"
					onClick={() => {
						openPath("our-services")
					}}
				>
					บริการของเรา
				</Menu.Item>

				<Menu.Item
					key="3"
					onClick={() => {
						openPath("product")
					}}
				>
					สินค้า
				</Menu.Item>

				<Menu.Item
					key="4"
					onClick={() => {
						openPath("about")
					}}
				>
					เกี่ยวกับเรา
				</Menu.Item>

				<Menu.Item
					key="5"
					onClick={() => {
						openPath("contact")
					}}
				>
					ติดต่อ
				</Menu.Item>
			</Menu>
		)
	}

	const genLeftMenu = () => {
		return (
			<div style={{ display: "flex", alignItems: "center", paddingRight: 16, fontSize: 18, marginTop: -2 }}>
				<div
					onClick={() => {
						openPath("home")
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>หน้าเเรก</label>
					</div>
				</div>

				<div
					onClick={() => {
						openPath("our-services")
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>บริการของเรา</label>
					</div>
				</div>

				<div
					onClick={() => {
						openPath("product")
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>สินค้า</label>
					</div>
				</div>

				<div
					onClick={() => {
						openPath("about")
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>เกี่ยวกับเรา</label>
					</div>
				</div>

				<div
					onClick={() => {
						openPath("contact")
					}}
					className="divStyle"
				>
					<div style={{ color: "white", cursor: "pointer" }}>
						<label style={{ padding: 12, cursor: "pointer" }}>ติดต่อ</label>
					</div>
				</div>
			</div>
		)
	}

	const openPath = (menu) => {
		let path = location.pathname
		if (menu === "home") {
			if (path === "/" || path === "/home") {
				scroller.scrollTo("home", {
					duration: 1500,
					delay: 100,
					smooth: true,
					offset: -210,
				});
			} else {
				navigate("/?menu=home")
			}
		} else if (menu === "our-services") {
			if (path === "/" || path === "/home") {
				scroller.scrollTo("our-services", {
					duration: 1500,
					delay: 100,
					smooth: true,
					offset: -78,
				});
			} else {
				navigate("/?menu=our-services")
			}
		} else if (menu === "product") {
			if (path === "/" || path === "/home") {
				scroller.scrollTo("product", {
					duration: 1500,
					delay: 100,
					smooth: true,
					offset: -78,
				});
			} else {
				navigate("/?menu=product")
			}
		} else if (menu === "about") {
			if (path === "/" || path === "/home") {
				scroller.scrollTo("about", {
					duration: 1500,
					delay: 100,
					smooth: true,
					offset: -78,
				});
			} else {
				navigate("/?menu=about")
			}
		} else if (menu === "contact") {
			if (path === "/" || path === "/home") {
				scroller.scrollTo("contact", {
					duration: 1500,
					delay: 100,
					smooth: true,
					offset: -78,
				});
			} else {
				navigate("/?menu=contact")
			}
		}
	}

	return (
		// <header>
		<div style={{}}>
			<div
				style={{
					paddingLeft: 0,
					paddingTop: 0,
					paddingBottom: 0,
					position: 'fixed',
					zIndex: 2,
					width: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.4)',
					overflow: 'hidden',
					boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
					height: 70,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between"
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ paddingLeft: 32, display: "grid", alignItems: "center", color: "white" }} >
						<label style={{ fontSize: 19, fontWeight: 500, width: 150 }}>
							สุนทรวาณิชย์2003
						</label>
					</div>
				</div>

				{width > 640 ?
					<div
						style={{
							display: "flex",
							paddingTop: 2,
							alignItems: "center",
							justifyContent: "flex-end",
							width: "100%"
						}}
					>
						{genLeftMenu()}
					</div>
					:
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
						<Drawer
							title={<label style={{ fontSize: 16, fontWeight: 100 }}>สุนทรวาณิชย์ 2003</label>} placement="right"
							closable={false}
							onClose={onClose}
							visible={state.visible}
							bodyStyle={{ padding: '0px' }}
							size="default"
						>
							{genRightMenu()}
						</Drawer>

						<Button
							type="primary"
							onClick={showDrawer}
							style={{
								height: 70,
								width: 85,
								borderRadius: 0
							}}
						>
							<Icon
								icon="charm:menu-hamburger"
								style={{
									fontSize: '2.5em',
									marginTop: 4,
									marginLeft: -8,
									color: "white"
								}}
							/>
						</Button>
					</div>
				}
			</div>
		</div>
		// </header>
	)
}