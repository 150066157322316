/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import {
    Row,
    Col,
    Modal
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

export default function Contact() {
    const { width } = useDimensions()

    const [isModalLineVisible, setIsModalLineVisible] = useState(false)

    const handleModalLine = () => {
        setIsModalLineVisible(false)
    }

    return (
        <div
            style={{
                paddingTop: 84,
                paddingBottom: 84,
                paddingLeft: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24),
                paddingRight: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24),
                backgroundColor: "white"
            }}
        >
            <Row gutter={[24, 32]}>
                <Col span={24} style={{ textAlign: "center" }}>
                    <label style={{ fontSize: 26 }}>ติดต่อ</label>
                </Col>

                <Col xs={24} md={12} xl={12}>
                    <div style={{ display: 'grid', alignItems: 'center', justifyContent: "center" }}>
                        <label style={{ paddingTop: 12 }}>ร้านสุนทรวาณิชย์ 2003</label>

                        <div style={{ display: "flex", paddingTop: 12 }}>
                            <div>
                                <Icon icon="mdi:address-marker" style={{ width: 15, height: 15, color: "#0099FF" }} />
                            </div>
                            <label style={{ paddingLeft: 8 }}>275 ถนนหทัยราษฎร์ เขตคลองสามวา แขวงสามวาตะวันตก กรุงเทพฯ 10510</label>
                        </div>
                        <div style={{ display: "flex", paddingTop: 12 }}>
                            <div>
                                <Icon icon="solar:phone-bold" style={{ width: 15, height: 15, color: "#0099FF" }} />
                            </div>
                            <label style={{ paddingLeft: 8 }}>02-906-6469, 088-954-5515, 088-654-5546</label>
                        </div>
                        <div style={{ display: "flex", paddingTop: 12 }}>
                            <div>
                                <Icon icon="clarity:email-solid" style={{ width: 15, height: 15, color: "#0099FF" }} />
                            </div>
                            <label style={{ paddingLeft: 8 }}>sunthornwanich2003@gmail.com</label>
                        </div>
                        <div style={{ display: "flex", paddingTop: 12 }}>
                            <div>
                                <Icon icon="carbon:time-filled" style={{ width: 15, height: 15, color: "#0099FF" }} />
                            </div>
                            <label style={{ paddingLeft: 8 }}>จันทร์-เสาร์ เวลา 08:00-17:30</label>
                        </div>

                        <label style={{ paddingTop: 12 }}></label>
                        <label style={{ paddingTop: 12 }}></label>
                        <label style={{ paddingTop: 12 }}></label>
                        <label style={{ paddingTop: 12 }}></label>

                        <div style={{ display: "flex", alignItems: "center", paddingTop: 12 }}>
                            <div
                                style={{ padding: 6, cursor: "pointer" }}
                                onClick={() => {
                                    setIsModalLineVisible(true)
                                }}
                            >
                                <Icon icon="fa6-brands:line" style={{ fontSize: '1.7em', color: "#11ff00" }} />
                            </div>
                            <div 
                            style={{ padding: 6, cursor: "pointer" }}
                            onClick={() => {
                                window.open("https://www.facebook.com/profile.php?id=61558709725381")
                            }}
                            >
                                <Icon icon="devicon:facebook" style={{ fontSize: '1.7em', color: "white" }} />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={12} xl={12}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7746.958201629885!2d100.71948723331761!3d13.870276884318955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d7b4e3e1a647b%3A0x8d7b0d46198c3c40!2z4Liq4Li44LiZ4LiX4Lij4Lin4Liy4LiT4Li04LiK4Lii4LmMIDIwMDM!5e0!3m2!1sth!2sth!4v1713686261345!5m2!1sth!2sth" style={{ border: 0, width: "100%", height: 240 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[51.505, -0.09]}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>
                    </MapContainer> */}
                </Col>
            </Row>


            <Modal
                title="ไลน์"
                visible={isModalLineVisible}
                onCancel={handleModalLine}
                // width={1000}
                footer={[]}
            >
                <img
                    style={{ objectFit: "cover", width: "100%", height: 500, borderRadius: 8 }}
                    src={`./assets/images/contact/line-qrcode.jpg`}
                />
            </Modal>
        </div>
    )
}