import React, { useState } from 'react'
import {
    Row,
    Col,
    Button
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { scroller } from "react-scroll"

export default function Main() {

    const { width } = useDimensions()

    return (
        <>
            <div
                style={{
                    backgroundImage: "url(./assets/images/background/company-building.jpg)",
                    backgroundSize: (width > 760 ? '100%' : '255%'),
                    width: '100% auto',
                    height: '100% auto',
                    alignItems: "center",
                    filter: "brightness(110%)"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: width > 760 ? "space-between" : "center",
                        paddingTop: width > 1200 ? 110 : (width > 760 && width < 1200 ? 110 : 110),
                        paddingBottom: 42,
                        paddingLeft: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24),
                        paddingRight: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "10%" : 24)
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            borderRadius: 16,
                            color: "white",
                            width: 360,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 32,
                            paddingBottom: 32
                        }}
                    >
                        <label style={{ fontSize: 26, paddingBottom: 6 }}>
                            จำหน่าย วัสดุก่อสร้าง ท่อ เสา แผ่นคอนกรีตทุกชนิด ราคาถูก 
                        </label>
                        <label style={{ fontSize: 20 }}>
                            ท่อใยหิน เสาเข็มหกเหลี่ยม
                            เสาคอนกรีตสำเร็จรูป
                            เสาตีนช้าง แผ่นพื้นสำเร็จ
                            อิฐบล็อค
                        </label>

                        <label style={{ fontSize: 20, paddingTop: 16 }}>
                            โทร
                            <br />
                            02-906-6469, 088-954-5515, 088-654-5546
                        </label>
                    </div>

                    {width > 760 ?
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <label style={{ fontSize: 20, paddingTop: 16, color: "white" }}>ร้านสุนทรวาณิชย์ 2003</label>
                        </div>
                        : []
                    }
                </div>

            </div>
        </>

    )
}