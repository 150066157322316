/* eslint-disable jsx-a11y/alt-text */
import { Button } from 'antd'
import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import {
    Row,
    Col
} from 'antd'
import { useNavigate } from 'react-router'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

export default function About() {

    const navigate = useNavigate()
    const { width } = useDimensions()

    return (
        <div
            style={{
                paddingTop: 84,
                paddingBottom: 84,
                paddingLeft: width > 1200 ? "20%" : (width > 760 && width < 1200 ? "10%" : 24),
                paddingRight: width > 1200 ? "20%" : (width > 760 && width < 1200 ? "10%" : 24),
                backgroundColor: "#0099FF",
                color: "white"
            }}
        >
            <Row gutter={[0, 24]}>
                <Col span={24} style={{ textAlign: "center" }}>
                    <label style={{ fontSize: 26 }}>เกี่ยวกับเรา</label>
                </Col>

                <Col xs={24} md={8} xl={8}>
                    <center>
                        <img
                            style={{ width: 200, borderRadius: 8 }}
                            src={`./assets/images/about_us/building.png`}
                        />
                    </center>
                </Col>
                <Col xs={24} md={16} xl={16}>
                    <div style={{ display: "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <label style={{  }}>สุนทรวาณิชย์ 2003</label>
                        <label style={{ paddingTop: 12 }}>
                            เราให้บริการ เสาคอนกรีต เเผ่นคอนกรีต ท่อคอนกรีต ยาวนานกว่า 10 ปี มประสบการณ์มากมาย ให้บริการลูกค้ามามากกว่า 1,000 ราย มั่นใจในคุณภาพ เเละบริการของเรา
                        </label>
                        <label style={{ paddingTop: 12 }}>
                            เรามุ่มมั่นที่จะบริการสินค้าเหล่านี้ ให้ตอบโจทย์ เเละมอบสิงที่ดีที่สุดให้กับลูกค้าทุกท่าน ขอบคุณที่เลือกเรา
                        </label>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
