/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { Icon } from '@iconify/react'
import Main from './components/main'
import OurServices from './components/ourServices'
import About from './components/about'
import Contact from './components/contact'
import Product from './components/product'
import {
    Row,
    Col,
    Button
} from 'antd'
import { Element, scroller } from 'react-scroll'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import { useLocation } from 'react-router'

export default function Home() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const menu = params.get("menu")

    const { width } = useDimensions()

    useEffect(() => {
        if (menu === "home") {
            scroller.scrollTo("home", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -78,
            })
        } else if (menu === "our-services") {
            scroller.scrollTo("our-services", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -78,
            })
        } else if (menu === "product") {
            scroller.scrollTo("product", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -78,
            })
        } else if (menu === "about") {
            scroller.scrollTo("about", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -78,
            })
        } else if (menu === "contact") {
            scroller.scrollTo("contact", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -78,
            })
        }
    }, [])

    return (
        <>
            <Header />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row>
                    <Col span={24}>
                        <Element name="home">
                            <Main />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Element name="our-services">
                            <OurServices />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Element name="product">
                            <Product />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Element name="about">
                            <About />
                        </Element>
                    </Col>
                    <Col span={24}>
                        <Element name="contact">
                            <Contact />
                        </Element>
                    </Col>
                </Row>
            </div>
        </>
    )
}
