/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Pagination, Button } from 'antd'
import { Icon } from '@iconify/react'
import Card from '../../../../../common/components/card'
import { getProductAllFetch } from '../../../../product/API'
import LoadingPage from '../../../../../common/components/loading/page'

export default function ProductList(props) {

    const [product, setProduct] = useState([])
    const [loading, setLoading] = useState(false)

    const size = 12

    const [page, setPage] = useState({
        minValue: 0,
        maxValue: size
    })

    const handleChange = value => {
        if (value <= 1) {
            setPage({
                minValue: 0,
                maxValue: size
            });
        } else {
            setPage({
                minValue: (value - 1) * size,
                maxValue: value * size
            })
        }
    }

    useEffect(() => {
        setProduct(props.product)
    }, [props.product])

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    return (
        <>
            <div style={{ borderRadius: 8 }}>
                <Row>
                    {!loading ?
                        <>
                            <Col span={24}>
                                <Row>
                                    {product
                                        ?.slice(page.minValue, page.maxValue)
                                        ?.map((item, index) => {
                                            return (
                                                <Col xs={24} sm={12} md={6} lg={6}>
                                                    <Card
                                                        key={index}
                                                        index={index + 1}
                                                        productId={item?.productId}
                                                        title={item?.title}
                                                        imageURL={item?.imageURL}
                                                        subTitle={item?.subTitle}
                                                        // height={240}

                                                        isPaddingLeft={true}
                                                        isPaddingRight={true}
                                                        isPaddingTop={true}
                                                    />
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Col>

                            <Col span={24} style={{ paddingTop: product?.length > 12 ? 12 : 0, paddingBottom: 12, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                {product?.length > size ?
                                    <center>
                                        <Pagination
                                            defaultCurrent={1}
                                            defaultPageSize={size}
                                            onChange={handleChange}
                                            total={product?.length ?? 0}
                                        />
                                    </center>
                                    : []
                                }

                                {/* <Button
                                        type="primary"
                                        onClick={() => {

                                        }}
                                    >
                                        <label>ดูเพิ่มเติม</label>
                                    </Button> */}
                            </Col>
                        </>
                        :
                        <Col span={24}>
                            <LoadingPage loading={loading} />
                        </Col>
                    }

                    {product?.length === 0 ?
                        <Col span={24}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="mdi:file-find" style={{ fontSize: '1.5em', color: "#0099ff" }} />
                                <label style={{ paddingLeft: 8 }}>ไม่พบผลิตภัณฑ์</label>
                            </div>
                        </Col>
                        : []
                    }
                </Row>
            </div>
        </>
    )
}
