/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react"
import { useNavigate } from 'react-router-dom'
import { Row, Col, Button, Modal, Card, AutoComplete } from 'antd'
import { Icon } from '@iconify/react'
import parse from 'html-react-parser'
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import ProductList from "./components/ProductList"
import { getProductBySearchFetch } from "../../../erp/manage/product/API"
import { getMainProductCategoryAllFetch, getProductByNameFetch, getSub1ProductCategoryByIdFetch } from "./API"
// import CarouselList from "../../../../common/components/banner/CarouselList"

import "./css/index.css";

export default function Product() {
    const navigate = useNavigate()
    const { width } = useDimensions()

    const [product, setProduct] = useState([])
    const [mainProductCategory, setMainProductCategory] = useState([])
    const [sub1ProductCategory, setSub1ProductCategory] = useState([])
    const [loading, setLoading] = useState(false)

    const searchRef = useRef('')

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const [productCategorySelected, setProductCategorySelected] = useState("all")
    const [sub1ProductCategorySelected, setsub1ProductCategorySelected] = useState({
        title: "ผลิตภัณฑ์ทั้งหมด",
        imageURL: "./assets/images/product_category/show_all.jpg",
        productSub1CategoryId: null
    })

    const [options, setOptions] = useState([])

    const onSelect = async (value) => {
        setLoading(true)
        
        // console.log('onSelect', value)
        setsub1ProductCategorySelected({
            title: value,
            imageURL: "./assets/images/product_category/search.jpg",
            productSub1CategoryId: null
        })
        setProductCategorySelected(null)

        await getProductBySearch(value, null)

        setLoading(false)
    }

    const handleSearch = async (value) => {
        let obj = {
            title: value, 
            productSub1CategoryId: null, // sub1ProductCategorySelected.productSub1CategoryId, 
            paging: 10
        }
        const result = await getProductByNameFetch(obj)
        // console.log("X ---- : ", result)
        let data = []
        result?.map((val) => data.push(val.title))
        let searchResult = await data
            .map((data) => {
                return {
                    value: data,
                    label: (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <span>{data}</span>
                        </div>
                    )
                }
            })

        // console.log("Y --- : ", value, searchResult)
        setOptions(value ? searchResult : [])
    }

    const getMainProductCategoryAll = async () => {

        let result = await getMainProductCategoryAllFetch()
        // console.log("getMainProductCategoryAllFetch : ", result)
        if (result) {
            setMainProductCategory(result)
        }
    }

    const getSub1ProductCategoryById = async (productMainCategoryId) => {

        let obj = {
            productMainCategoryId
        }

        let result = await getSub1ProductCategoryByIdFetch(obj)
        // console.log("getSub1ProductCategoryByIdFetch : ", result, productMainCategoryId)
        if (result) {
            setSub1ProductCategory(result)
        }
    }

    const getProductBySearch = async (title, productSub1CategoryId) => {

        let obj = {
            title,
            productSub1CategoryId: (productSub1CategoryId === "all" ? null : productSub1CategoryId)
        }

        let result = await getProductBySearchFetch(obj)
        // console.log("getProductBySearchFetch : ", result, obj)
        if (result) {
            setProduct(result)
        }
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getMainProductCategoryAll()
        await getProductBySearch(null, null)

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    const genCategory = () => {

        return (
            <div style={{ paddingBottom: 24 }}>
                <Row gutter={[6, 6]}>
                    {mainProductCategory?.map((val, index) => {
                        return (
                            <Col key={index} xs={24} md={12} xl={6}>
                                <Button
                                    size="large"
                                    style={{ width: "100%" }}
                                    type={productCategorySelected === val.productMainCategoryId ? "primary" : "default"}
                                    onClick={async () => {
                                        setProductCategorySelected(val.productMainCategoryId)
                                        await getSub1ProductCategoryById(val.productMainCategoryId)
                                        setModal({
                                            isShow: true,
                                            title: val.title
                                        })
                                    }}
                                >
                                    <label style={{ cursor: "pointer" }}>{val?.title}</label>
                                </Button>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        )
    }

    return (
        <div
            style={{
                paddingTop: 84,
                paddingBottom: 68,
                paddingLeft: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 0 : 0),
                paddingRight: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 12 : 12),
            }}
        >
            <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                    {genCategory()}
                </Col>
                <Col span={24} style={{ textAlign: "center", paddingBottom: 12 }}>
                    <label style={{ fontSize: 26 }}>รายการสินค้า</label>
                </Col>
                <Col span={24}>
                    <div style={{ display: (width > 760 ? "flex" : "grid") , alignItems: "center", justifyContent: "space-between", backgroundColor: '#EEEEEE', borderRadius: 16, paddingRight: (width > 430 ? 12 : 0) }}>
                        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                            <img src={sub1ProductCategorySelected?.imageURL} style={{ objectFit: "cover", width: (width > 430 ? 236 : 140), height: (width > 430 ? 103 : 80), borderTopLeftRadius: 16, borderBottomLeftRadius: 16 }} />
                            <label style={{ paddingLeft: 24, fontSize: 20 }}>{sub1ProductCategorySelected?.title}</label>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', width: (width > 760 ? 350 : "96%"), padding: (width > 760 ? 0 : 12) }}>
                            <AutoComplete
                                onChange={(e) => {
                                    // console.log("e --- : ", e)
                                    searchRef.current = e
                                }}
                                // ref={searchRef}
                                dropdownMatchSelectWidth={252}
                                style={{
                                    width: '100%'
                                }}
                                value={searchRef.current}
                                options={options}
                                size="large"
                                placeholder="ค้นหาชื่อผลิตภัณฑ์"
                                onSearch={handleSearch}
                                onSelect={onSelect}
                            />
                            <div style={{ paddingLeft: 6 }}>
                                <Button
                                    type="primary"
                                    style={{ 
                                        width: 45, 
                                        // border: '1px solid #dedede' 
                                        // borderRadius: "0px 10px 10px 0px"
                                    }}
                                    size="large"
                                    onClick={ async (e) => {
                                        e.preventDefault()
                                        setLoading(true)
            
                                        // console.log('searchRef.current', searchRef.current)
                                        
                                        if (searchRef.current === "") {
                                            setsub1ProductCategorySelected({
                                                title: "ผลิตภัณฑ์ทั้งหมด",
                                                imageURL: "./assets/images/product_category/show_all.jpg",
                                                productSub1CategoryId: null
                                            })
                                        } else {
                                            setsub1ProductCategorySelected({
                                                title: searchRef.current,
                                                imageURL: "./assets/images/product_category/search.jpg",
                                                productSub1CategoryId: null
                                            })
                                        }

                                        await getProductBySearch(
                                            (searchRef.current === "" ? null : searchRef.current), 
                                            null
                                        )
                                        setProductCategorySelected(null)

                                        setLoading(false)
                                    }}
                                >
                                    <Icon icon="bi:search" style={{ color: "white", width: 15, height: 15, marginTop: 5.5 }} />
                                </Button>
                            </div>

                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <div
                        style={{
                            paddingTop: 12,
                            paddingBottom: 0
                        }}
                    >
                        <ProductList product={product} loading={loading} />
                    </div>
                </Col>
            </Row>

            <Modal
                maskClosable={false}
                title={
                    <strong>
                        <span className="topic-color-bold">{modal.title}</span>
                    </strong>
                }
                visible={modal.isShow}
                width={700}
                onCancel={() => {
                    // close modal
                    setModal({
                        isShow: false,
                        title: null,
                    })
                }}
                footer={[]}
            >
                <div style={{ height: 380, overflow: "scroll", overflowX: "hidden" }}>
                    <Row gutter={[12, 12]} style={{ paddingTop: 6 }}>
                        {sub1ProductCategory?.map((val, index) => {
                            return (
                                <Col key={index} xs={24} md={12} xl={12}>
                                    <div
                                        className="sub-category-card"
                                        hoverable
                                        onClick={async () => {
                                            setLoading(true)
                                            setsub1ProductCategorySelected(val)
                                            searchRef.current = ""
                                            await getProductBySearch(null, val.productSub1CategoryId)

                                            // close modal
                                            setModal({
                                                isShow: false,
                                                title: null,
                                            })

                                            setLoading(false)
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "flex-start" }}>
                                            <img src={val?.imageURL} style={{ objectFit: "cover", width: 110, height: 80, borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }} />
                                            <label style={{ paddingLeft: 12, cursor: "pointer", paddingTop: 12, paddingBottom: 12 }}>{val.title}</label>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </Modal>
        </div>
    )
}