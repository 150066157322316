import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getMainProductCategoryAllFetch = async () => { // Done
    try {
        const result = await httpClient.get(server.GET_MAIN_PRODUCT_CATEGORY_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getSub1ProductCategoryAllFetch = async () => { // Done
    try {
        const result = await httpClient.get(server.GET_SUB1_PRODUCT_CATEGORY_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getSub1ProductCategoryByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_SUB1_PRODUCT_CATEGORY_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getProductByNameFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_PRODUCT_BY_NAME_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getMainProductCategoryAllFetch,
    getSub1ProductCategoryAllFetch,
    getSub1ProductCategoryByIdFetch,
    getProductByNameFetch

    // insert

    // update

    // delete
}