/* eslint-disable jsx-a11y/alt-text */
import { serverUrl } from "../../../constants"
import "./css/index.css"
import React from 'react'
import { Button } from 'antd'
import { Icon } from '@iconify/react'
import { useNavigate } from "react-router"

export default function Card(props) {

    const navigate = useNavigate()

    return (
        <div
            style={{
                paddingLeft: 12,
                paddingRight: (props.index % 10) ? 0 : 12,
                paddingTop: 12,
                // paddingBottom: 12,
                width: props.width,
            }}
        >
            <div className="blog-card">
                <div
                    style={{
                        borderRadius: 8,
                        width: "100%",
                        height: props?.height
                    }}
                >
                    <div style={{ paddingBottom: 6 }}>
                        {props?.imageURL ?
                            <img
                                src={`${serverUrl}/${props?.imageURL}`}
                                style={{ objectFit: "cover", width: "100%", height: 200, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                            />
                            :
                            <img
                                src={"./assets/images/default/df-img.png"}
                                style={{ objectFit: "cover", width: "100%", height: 200, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                            />
                        }


                    </div>

                    <div style={{ height: 130 }}>
                        <div
                            style={{
                                paddingLeft: 6,
                                paddingRight: 6,
                                paddingBottom: 10,
                                fontWeight: 600
                            }}
                        >
                            <div className="blog-subtitle">
                                <label>{props?.title}</label>
                            </div>
                        </div>

                        <div
                            style={{
                                paddingLeft: 6,
                                paddingRight: 6,
                                paddingBottom: 8,
                                fontWeight: 400,
                                maxHeight: 60,
                                height: 60
                            }}
                        >
                            <div className="blog-subtitle">
                                <label>{props?.subTitle}</label>
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingBottom: 12
                        }}
                    >
                        <Button
                            type="primary"
                            style={{ float: 'right' }}
                            onClick={() => {
                                navigate("/product?productId=" + props.productId)
                                window.scrollTo(0, 0)
                            }}
                        >
                            <label style={{ cursor: "pointer" }}>รายละเอียด</label>
                        </Button>
                    </div>
                </div>
            </div>
        </div >
    )
}
