import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getProductAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_PRODUCT_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getProductByIdFetch = async (productId) => {
    try {
        const result = await httpClient.post(server.GET_PRODUCT_BY_ID_URL, { productId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getProductAllFetch,
    getProductByIdFetch,

    // insert

    // update

    // delete

}