/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Header from '../../common/components/header'
import { getProductByIdFetch } from './API'
import { useDimensions } from '../../common/mamager/ScreenManager'
import parse from 'html-react-parser'
import Contact from '../home/components/contact'
import { useLocation } from 'react-router'
import { serverUrl } from '../../constants'
// import Video from '../../common/components/video'

export default function Product() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const productId = params.get("productId")

    const [loading, setLoading] = useState(false)

    const { width } = useDimensions()
    const [product, setProduct] = useState()

    const getPartnerAll = async () => {
        setLoading(true)

        let result = await getProductByIdFetch(Number(productId))
        if (result) {
            setProduct(result)
        }
        // console.log("result : ", result)

        setLoading(false)
    }

    useEffect(() => {
        getPartnerAll()
    }, [])

    return (
        <>
            <Header />

            <div
                style={{
                    paddingTop: 70,
                    paddingLeft: width > 760 ? "15%" : 0,
                    paddingRight: width > 760 ? "15%" : 0,
                    backgroundColor: "#EEEEEE"
                }}
            >
                {!loading ?
                    <Row
                        gutter={[24, 24]}
                        style={{
                            backgroundColor: "white",
                            paddingTop: 24,
                            paddingBottom: 84,
                            paddingLeft: 24,
                            paddingRight: 24,
                        }}
                    >
                        <Col span={24}>
                            <label style={{ fontWeight: 600, fontSize: 20 }}>{product?.title ?? "-"}</label>
                        </Col>

                        <Col xs={24} md={12} xl={8}>
                            <div style={{ paddingBottom: 6 }}>
                                <img
                                    src={`${serverUrl}/${product?.imageURL}`}
                                    style={{ objectFit: "cover", width: "100%", height: 200, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                                />
                            </div>
                        </Col>
                        <Col xs={24} md={12} xl={16}>
                            <div style={{ paddingBottom: 6 }}>
                                <label style={{ fontWeight: 600 }}>รายละเอียด</label>
                            </div>
                            <div style={{ paddingBottom: 12 }}>
                                <label>{product?.detail ? parse(product?.detail ?? "-") : "-"}</label>
                            </div>

                            <div style={{ paddingBottom: 6 }}>
                                <label style={{ fontWeight: 600 }}>โปรโมชั่น</label>
                            </div>
                            <div>
                                <label>{product?.promotion ? parse(product?.promotion ?? "-") : "-"}</label>
                            </div>
                        </Col>

                        <Col span={24} style={{ borderTop: "2px solid #EEEEEE" }}>
                            <Contact />
                        </Col>
                    </Row>
                    : []
                }
            </div>
        </>
    )
}
