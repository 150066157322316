/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-duplicate-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect } from 'react'
import {
    Button,
    Layout,
    Menu
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { useNavigate, useLocation } from 'react-router-dom'
import ProductManage from './manage/product'
import { getAdminByIdFetch } from './API'
// import AdminManage from './manage/admin'

const { Sider } = Layout
// const { SubMenu } = Menu

export default function ERP(props) {
    const navigate = useNavigate()
    const location = useLocation()

    const [loading, setLoading] = useState(true)

    let username = location.state ? location.state.username : null
    let password = location.state ? location.state.password : null

    const [selectedMenuItem, setSelectedMenuItem] = useState("manage-product")

    const componentsSwitch = (type) => {
        switch (type) {
            // case "manage-admin":
            //     return <AdminManage />
            case "manage-product":
                return <ProductManage />
            default:
                return null
        }
    }

    const detectLogin = async () => {
        return await getAdminByIdFetch(username, password) // & where isActive = 1
    }

    const getBaseApi = async () => {
        // console.log(username, password)
        // setInterval(async () => {
        setLoading(true)
        let result = await detectLogin()
        // console.log("detectLogin : ", result)
        if (!result?.isLogin) {
            navigate("/login-suansawan-wanich-back-office")
            // Notification("warning", "เเจ้งเตือน", "เกิดข้อผิดพลาดในการ Detect login !")
        }
        setLoading(false)
        // }, 10000)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            {!loading ?
                <div style={{ backgroundColor: '#ECECEC' }}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <PageHeader
                            title={
                                <>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ paddingLeft: 10, display: "grid", alignItems: "center", color: "white" }} >
                                            <label style={{ fontSize: 20, fontWeight: 500 }}>
                                                สุนทรวาณิชย์ 2003 (Administrator)
                                            </label>
                                            <label style={{ color: "white", fontSize: 16, fontWeight: "initial" }}>
                                                <label style={{}}>ผู้ใช้งาน : {location.state ? location.state.username : "-"}</label>
                                            </label>
                                        </div>
                                    </div>
                                </>
                            }
                            extra={[
                                <Button
                                    type="text"
                                    onClick={() => {
                                        navigate("/suansawan-wanich-back-office", { state: null })
                                        navigate("/login-suansawan-wanich-back-office")
                                    }}
                                    style={{ marginTop: 16, color: "white" }}
                                >ออกจากระบบ</Button>
                            ]}
                            style={{ backgroundColor: 'black' }}
                        >
                        </PageHeader>
                        <Layout>
                            <Sider
                                width={300}
                                className="site-layout-background"
                            >
                                <Menu
                                    defaultSelectedKeys={['1']}
                                    defaultOpenKeys={['mamage-user']}
                                    mode="inline"
                                    theme="dark"
                                    selectedKeys={selectedMenuItem}
                                    onClick={async (e) => {
                                        setSelectedMenuItem(e.key)
                                    }}
                                    style={{ height: '100%' }}
                                >
                                    {/* <Menu.Item key="manage-admin">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการ Admin</label>
                                    </Menu.Item> */}

                                    <Menu.Item key="manage-product">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการสินค้า</label>
                                    </Menu.Item>
                                </Menu>
                            </Sider>
                            <article style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                                <div style={{ padding: 20 }}>
                                    {componentsSwitch(selectedMenuItem)}
                                </div>
                            </article>
                        </Layout>
                    </div>
                </div>
                : []}
        </>
    )
}
