/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

export default function OurServices() {

    const { width } = useDimensions()

    return (
        <div>
            <div style={{ backgroundColor: "white", borderRadius: 8, color: "white" }}>
                <Row
                    gutter={[24, 24]}
                    style={{
                        paddingTop: 84,
                        paddingBottom: 84,
                        paddingLeft: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "7%" : 24),
                        paddingRight: width > 1200 ? "15%" : (width > 760 && width < 1200 ? "7%" : 24),
                        backgroundColor: "#0099FF"
                    }}
                >
                    <Col span={24} style={{ textAlign: "center" }}>
                        <label style={{ fontSize: 26 }}>บริการของเรา</label>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={6}>
                        <div style={{ display: "grid" }}>
                            <label style={{ fontSize: 20, paddingBottom: 10 }}>เราเป็นตัวเเทนจำหน่าย</label>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <Icon icon="icon-park-solid:correct" style={{ fontSize: '1.5em', color: "white" }} />
                                <label style={{ fontSize: 20, paddingLeft: 8 }}>ท่อใยหิน</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <Icon icon="icon-park-solid:correct" style={{ fontSize: '1.5em', color: "white" }} />
                                <label style={{ fontSize: 20, paddingLeft: 8 }}>เสาเข็มหกเหลี่ยม</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <Icon icon="icon-park-solid:correct" style={{ fontSize: '1.5em', color: "white" }} />
                                <label style={{ fontSize: 20, paddingLeft: 8 }}>เสาคอนกรีตสำเร็จรูป</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <Icon icon="icon-park-solid:correct" style={{ fontSize: '1.5em', color: "white" }} />
                                <label style={{ fontSize: 20, paddingLeft: 8 }}>เสาตีนช้าง</label>
                            </div>
                            <div style={{ display: "flex", paddingBottom: 10 }}>
                                <Icon icon="icon-park-solid:correct" style={{ fontSize: '1.5em', color: "white" }} />
                                <label style={{ fontSize: 20, paddingLeft: 8 }}>แผ่นพื้นสำเร็จ</label>
                            </div>
                            <div style={{ display: "flex" }}>
                                <Icon icon="icon-park-solid:correct" style={{ fontSize: '1.5em', color: "white" }} />
                                <label style={{ fontSize: 20, paddingLeft: 8 }}>อิฐบล็อค</label>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={18}>
                        <div style={{ display: "grid" }}>
                            <label style={{ fontSize: 20, paddingBottom: 10 }}>เราบริการด้วยใจ เน้นสินค้าที่มีมาตรฐาน เเละคุณภาพสูงสุด ที่คัดสรรมาเป็นอย่างดี ใช้งานได้อย่างหายห่วง</label>
                            <Row gutter={[24,24]}>
                                <Col xs={24} sm={12} md={8} lg={8}>
                                    <img
                                        style={{ objectFit: "cover", width: "100%", height: 240, borderRadius: 8 }}
                                        src={`./assets/images/our_service/our_service_a.jpg`}
                                    />
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={8}>
                                    <img
                                        style={{ objectFit: "cover", width: "100%", height: 240, borderRadius: 8 }}
                                        src={`./assets/images/our_service/our_service_b.png`}
                                    />
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={8}>
                                    <img
                                        style={{ objectFit: "cover", width: "100%", height: 240, borderRadius: 8 }}
                                        src={`./assets/images/our_service/our_service_c.jpg`}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
